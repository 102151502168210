import { allHeroesTableColumnHelper } from "../helpers/columnHelpers";
import DeadlockTier from "~/shared/components/DeadlockTier";
import { HeroTier } from "~/types/Heroes";

const tierOrder: Record<HeroTier, number> = { S: 1, A: 2, B: 3, C: 4, D: 5 };

export const tierColumn = allHeroesTableColumnHelper.accessor("tier", {
  header: "Tier",
  id: "tier",
  cell: (info) => {
    const value = info.getValue();
    return (
      <div className="flex w-full justify-center">
        <DeadlockTier tier={value as HeroTier} />
      </div>
    );
  },
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  sortingFn: (a, b) => tierOrder[a.getValue("tier") as HeroTier] - tierOrder[b.getValue("tier") as HeroTier],
});
