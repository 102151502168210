import { HeroTier } from "~/types/Heroes";
import { AllHeroesTable } from "../schemas";
import { HeroRank } from "@portal-frontend-ssr/blast-api";

export const mapToTableData = (heroData: HeroRank[]): AllHeroesTable[] => {
  const tierOrder: Record<HeroTier, number> = { S: 1, A: 2, B: 3, C: 4, D: 5 };
  const sortedHeroData = heroData.sort((a, b) => {
    if (a.tier && b.tier) {
      if (tierOrder[a.tier] !== tierOrder[b.tier]) {
        return tierOrder[a.tier] - tierOrder[b.tier];
      }
    }
    return b.winRate - a.winRate;
  });

  return sortedHeroData.map((hero, index) => {
    return {
      position: index + 1,
      hero: {
        heroId: hero.heroId,
        heroName: hero.heroName,
      },
      tier: hero.tier ?? "S",
      winRate: hero.winRate,
      trendRate: hero.trendPercentage ?? 0,
      pickRate: hero.pickRate,
      networth: hero.networth ?? 0,
      matches: hero.matchesPlayed,
    };
  });
};
