import { allHeroesTableColumnHelper } from "../helpers/columnHelpers";

export const trendColumn = allHeroesTableColumnHelper.accessor("trendRate", {
  header: "WR Trend",
  id: "wrTrend",
  cell: (info) => {
    const value = info.getValue();
    return (
      <div className="flex w-full items-center justify-center">
        <label className="font-style-label-l4">{value.toFixed(2)}%</label>
      </div>
    );
  },
});
