import { flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { AllHeroesTable as AllHeroesTableType } from "./schemas";
import { mapToTableData } from "./helpers/mapToTableData";
import { matchesColumn } from "./columns/matchesColumn";
import { heroColumn } from "./columns/heroColumn";
import { tierColumn } from "./columns/tierColumn";
import { winRateColumn } from "./columns/winRateColumn";
import { pickRateColumn } from "./columns/pickRateColumn";
import { trendColumn } from "./columns/trendColumn";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { Link } from "@remix-run/react";
import { Icon } from "@portal-frontend-ssr/ui";
import { rankColumn } from "./columns/rankColumn";
import { HeroRank } from "@portal-frontend-ssr/blast-api";
import { Hero } from "@portal-frontend-ssr/blast-data-api";

const columns = [rankColumn, heroColumn, tierColumn, winRateColumn, trendColumn, pickRateColumn, matchesColumn];

const reducedColumns = [heroColumn, tierColumn, pickRateColumn, winRateColumn];

interface AllHeroesTableProps {
  reduced?: boolean;
  heroesRanks: HeroRank[];
  heroes: Hero[];
}

export const AllHeroesTable = ({ reduced, heroesRanks, heroes }: AllHeroesTableProps) => {
  const heroesMap = useMemo(() => {
    return heroes.reduce(
      (acc, hero) => {
        acc[hero.heroId] = hero;
        return acc;
      },
      {} as Record<string, Hero>,
    );
  }, [heroes]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchTerm, setSearchTerm] = useState("");

  const tableData: AllHeroesTableType[] = useMemo(() => {
    return mapToTableData(heroesRanks).filter((data) =>
      data.hero.heroName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [heroesRanks, searchTerm]);

  const table = useReactTable<AllHeroesTableType>({
    data: tableData,
    columns: reduced ? reducedColumns : columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  return (
    <div className="flex flex-col gap-5">
      <div className="flex w-full flex-col gap-5">
        <div>
          <div className="font-style-label-l3">Deadlock heroes</div>
        </div>

        <div className="flex h-9 w-full items-center rounded-full border border-primary-100 px-4">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for a Hero"
            className="grow border-none bg-transparent text-foreground-100 outline-none font-style-body-b3"
          />
          {searchTerm ? (
            <Icon icon="closeCircled" className="size-4 cursor-pointer" onClick={() => setSearchTerm("")} />
          ) : (
            <Icon icon="magnifyingGlass" className="size-4" />
          )}
        </div>
      </div>
      <div className="w-full overflow-auto">
        <table className="w-full border-separate border-spacing-x-0 border-spacing-y-1">
          <thead>
            <tr>
              {table.getFlatHeaders().map((header) => (
                <th
                  key={header.id + header.column.id}
                  className={classNames(
                    "px-2 py-1 text-neutral font-style-label-l4 first:pl-3 sd:min-w-28 xl:min-w-40",
                    {
                      "text-start sd:w-full": header.id === "hero",
                      "min-w-8 sd:!min-w-8": header.id == "rank",
                      "sd:!min-w-20": reduced,
                    },
                  )}
                  tabIndex={0}
                  onClick={header.column.getToggleSortingHandler()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      header.column.toggleSorting();
                    }
                  }}
                >
                  <span
                    className={classNames(
                      header.column.getCanSort() ? "cursor-pointer select-none" : "cursor-default",
                      "flex items-center justify-center",
                      {
                        "!justify-start": header.id === "hero",
                      },
                    )}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <div className="flex size-4 place-content-center items-center gap-0.5">
                        {{
                          asc: <Icon icon="chevronUp" className="fill-neutral" />,
                          desc: <Icon icon="chevronDown" className="fill-neutral" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <Icon icon="chevronUpDown" className="fill-neutral" />
                        )}
                      </div>
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="relative cursor-pointer bg-background-95 hover:bg-background-90">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="relative py-1.5 first:rounded-s-small first:pl-3 last:rounded-e-small">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}

                    <Link
                      to={`/heroes/${heroesMap[row.original.hero.heroId]?.heroSlug}`}
                      className="absolute left-0 top-0 size-full"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
