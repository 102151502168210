import { allHeroesTableColumnHelper } from "../helpers/columnHelpers";

export const matchesColumn = allHeroesTableColumnHelper.accessor("matches", {
  header: "Matches",
  id: "matches",
  cell: (info) => {
    const value = info.getValue();
    return (
      <div className="flex w-full items-center justify-center">
        <label className="font-style-label-l4">{value}</label>
      </div>
    );
  },
});
