import { generateDeadlockHeroImage } from "@portal-frontend-ssr/blast-assets-api";
import { allHeroesTableColumnHelper } from "../helpers/columnHelpers";

export const heroColumn = allHeroesTableColumnHelper.accessor("hero", {
  header: "Hero",
  id: "hero",
  cell: (info) => {
    const hero = info.getValue();
    return (
      <div className="flex w-full flex-row items-center gap-2">
        <img
          className="size-6 rounded-full object-cover"
          src={generateDeadlockHeroImage({ category: "card", heroId: hero.heroId })}
          alt={hero.heroName}
        />
        <span className="line-clamp-1 font-style-body-b3">{hero.heroName}</span>
      </div>
    );
  },
  sortingFn: (a, b) => a.original.hero.heroName.localeCompare(b.original.hero.heroName),
  sortDescFirst: false,
});
