import classNames from "classnames";
import { HeroTier } from "~/types/Heroes";

export const Tiers: Record<HeroTier, string> = {
  S: "bg-gradient-to-tr from-[#fff] via-[#EBB2FF] to-[#A3B1FF]",
  A: "bg-gradient-to-tr from-[#FFFADD] via-[#FFDA79] to-[#FFD700]",
  B: "bg-gradient-to-tr from-[#fff] via-[#DFDFDF] to-[#909090]",
  C: "bg-gradient-to-tr from-[#BC7838] via-[#CA915D] to-[#F3DDCA]",
  D: "bg-white",
};

export const TierGradient: Record<HeroTier, string> = {
  S: "linear-gradient(90deg, #fff 0%, #EBB2FF 50%, #A3B1FF 100%)",
  A: "linear-gradient(90deg, #FFFADD 0%, #FFDA79 50%, #FFD700 100%)",
  B: "linear-gradient(90deg, #fff 0%, #DFDFDF 50%, #909090 100%)",
  C: "linear-gradient(90deg, #BC7838 0%, #CA915D 50%, #F3DDCA 100%)",
  D: "linear-gradient(90deg, #fff 0%, #fff 50%, #fff 100%)",
};

export default function DeadlockTier({ tier, className }: { tier: HeroTier; className?: string }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const bgColor = Tiers[tier] ?? "bg-white";
  return (
    <div
      className={classNames(
        "flex size-5 items-center justify-center rounded-small font-style-label-l3",
        className,
        bgColor,
      )}
    >
      <span className="!tracking-normal text-background-100">{tier}</span>
    </div>
  );
}
