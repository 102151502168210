import { allHeroesTableColumnHelper } from "../helpers/columnHelpers";

export const winRateColumn = allHeroesTableColumnHelper.accessor("winRate", {
  header: "Win %",
  id: "winRate",
  cell: (info) => {
    const value = info.getValue();
    return (
      <div className="flex w-full items-center justify-center">
        <label className="font-style-label-l4">{(value * 100)?.toFixed(2)}%</label>
      </div>
    );
  },
});
