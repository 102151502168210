import { allHeroesTableColumnHelper } from "../helpers/columnHelpers";

export const rankColumn = allHeroesTableColumnHelper.accessor("position", {
  header: "Rank",
  id: "rank",
  enableSorting: false,
  cell: (info) => {
    const value = info.getValue();
    return (
      <div className="flex items-center justify-center">
        <label className="font-style-label-l4">#{value}</label>
      </div>
    );
  },
});
